
import { Options, Vue } from 'vue-class-component';
import { CommunityClient } from '@/services/Services';
import { getUniversalDate } from '@/utils';
import { selfUrl } from '@/config';
import SwiperSlides from '@/components/swiperSlides.vue';
import ModalSuccessError from '@/components/modalSuccessError.vue';
import ShareButton from '@/components/shareButton.vue';
import store from '@/store';
import * as OM from '@/model';
import { Watch } from 'vue-property-decorator';
import ConfirmModal from '@/components/confirmModal.vue';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        SwiperSlides,
        ShareButton
    }
})
export default class Community extends Vue {

    loaded: boolean = false;
    notFound: boolean = false;
    closeStatus = OM.CommunityType.Close;
    isEvent: boolean = false;
    isFiera: boolean = false;
    shareUrl: string = "";

    userIdentifier: string = "";
    isAdmin: boolean = false;
    isOwner: boolean = false;
    adminsCommunity: string[] = [];

    slug: string = "";

    created() {
        this.init();
    }
    
    @Watch("$route.params.slug")
    async init() {
        var slug = this.$route.params.slug;

        if(!slug)
            return;

        this.slug = slug.toString();
            
        var community  = await CommunityClient.getBySlug(this.$route.params.slug.toString())
        
        if(store.state.user && store.state.user.identifier)
            this.userIdentifier = store.state.user.identifier;
        
        if(this.$route.fullPath.indexOf("communities") == -1 && this.$route.fullPath.indexOf("fiera") == -1 && this.$route.fullPath.indexOf("events") == -1)
            return;

        this.shareUrl = selfUrl + "communities/" + this.$route.params.slug + "/Bacheca";
    
        this.isEvent = this.$route.path.indexOf("events") > -1;
        this.isFiera = this.$route.path.indexOf("fiera") > -1;

        var proms = [];
        proms.push(CommunityClient.getBySlug(this.slug));
        proms.push(CommunityClient.registerNewUserAccess(community.identifier));

        Promise.all(proms)
        .then(xs => {
            if(!xs[0]){
                store.state.community = new OM.CommunityDetailVm();
                this.notFound = true;
                //TODO: open modal not found
                //<div v-if="notFound" class="px-4 position-relative">
                //    <div class="communityTitle mt-3">
                //        <localized-text :localizedKey="'app_communitynontrovata'" :text="'Community non trovata'"></localized-text>
                //    </div>
                //    <div class="subscribersCount">
                //        <localized-text :localizedKey="'app_potrebbeesserestatarimossadalproprietario'" :text="'Potrebbe essere stata rimossa dal proprietario o dagli amministratori'"></localized-text>
                //    </div>
                //</div>
            } else {
                
                store.state.community = xs[0];

                var admins = store.state.community.admins.map( x => x.identifier);
                this.isAdmin = false;

                if(store.state.user){
                    if(admins.includes(store.state.user.identifier))
                        this.isAdmin = true;

                    this.isOwner = store.state.community.owner.identifier == store.state.user.identifier;
                }
                    
                store.state.community.dateFrom = <any>getUniversalDate(store.state.community.dateFrom); 
                store.state.community.dateTo = <any>getUniversalDate(store.state.community.dateTo); 
            }

            this.loaded = true;
        })
    }

    get isEventsActive(){
        return this.$route.path.indexOf("calendar") > -1;
    }

    openPeopleModal(model: OM.NameIdentifier[]) {
        store.state.allPeoplePage.title = store.state.community.title;
        store.state.allPeoplePage.model = model;
        this.$router.push('/allPeople');
    }

    openPerson(item: OM.NameIdentifier) {
        if(item.identifier == store.state.user.identifier)
            return;
        
        this.$router.push('/profile/' + item.identifier);
    }

    like() {
        if(store.state.community.isLiked || store.state.community.isLikeRequestSent ){
            var object = {};
            var messageKey = store.state.community.isLiked ? "app_seisicurodivolersmetterediseguire{{communityname}}" : "app_seisicurodivolerannullarelarichiesta";
            var messageValue = store.state.community.isLiked ? "Vuoi davvero smettere di seguire {{communityname}}?" : "Sei sicuro di voler annullare la richiesta?";
            
            if(store.state.community.isLiked)
                (<any>object).communityname = store.state.community.title;
            
            var messageText = LocalizationServices.getLocalizedValue(messageKey, messageValue, "", "", object);
            
                
            this.$opModal.show(ConfirmModal, {
                text: messageText,
                okCb:() =>{
                    CommunityClient.unLike(store.state.community.identifier)
                    .then(x => {
                        this.init();
                    })
                    this.$opModal.closeLast();

                },
            })
                
        } else {
            CommunityClient.like(store.state.community.identifier)
            .then(x => {
                if(store.state.community.communityType == OM.CommunityType.OnRequest){
                    var message = LocalizationServices.getLocalizedValue("app_richiestainoltrataconsuccesso", "Richiesta inoltrata con successo!");
                    this.$opModal.show(ModalSuccessError, {
                        message: message,
                    })
                }

                this.init();
            }).catch( err => {
                this.$opModal.show(ModalSuccessError, {
                    isError: true,
                    message: err.message,
                })
            })
        }
    }

    get canSee() {
        return store.state.community.communityType == OM.CommunityType.Public || store.state.community.isLiked || store.state.community.canSettings;
    }

    get updatedCommunity() {
        return store.state.community;
    }

    get bachecaRouteName() {
        if (this.isEvent)
            return "events_bacheca";
        else if (this.isFiera)
            return "fiera_attivita";
        else
            return "community_bacheca";
    }
    get infoRouteName() {
        if (this.isEvent)
            return "events_info";
        else if (this.isFiera)
            return "fiera_info";
        else
            return "community_info";
    }
    get forumRouteName() {
        if (this.isEvent)
            return "events_forum";
        else if (this.isFiera)
            return "fiera_forum";
        else
            return "community_forum";
    }
    get shopRouteName() {
        if (this.isEvent)
            return "events_shop";
        else if (this.isFiera)
            return "fiera_shop";
        else
            return "community_shop";
    }

}

